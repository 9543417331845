<template>
  <main class="main-content  mt-0">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
              <div class="card card-plain mt-8">
                <div class="card-header pb-0 text-left bg-transparent">
                  <h3 class="font-weight-bolder text-info text-gradient">С возвращением))</h3>
                  <p class="mb-0">Войдите в свой аккаунт</p>
                  <div v-if="error" class="alert alert-danger" role="alert">
                    <strong>{{ message }}</strong>
                  </div>
                </div>
                <div class="card-body">
                  <form role="form">
                    <label>Логин</label>
                    <div class="mb-3">
                      <input v-model="login" type="text" class="form-control" placeholder="login" aria-label="login" aria-describedby="login-addon">
                    </div>
                    <label>Пароль</label>
                    <div class="mb-3">
                      <input v-model="password" type="text" class="form-control" placeholder="Password" aria-label="Password" aria-describedby="password-addon">
                    </div>
                    <div class="text-center">
                      <button @click.prevent="loginUser()" :disabled="disabled" type="button" class="btn bg-gradient-info w-100 mt-4 mb-0">Войти</button>
                    </div>
                  </form>
                </div>
                <div class="card-footer text-center pt-0 px-lg-2 px-1">
                  <p class="mb-4 text-sm mx-auto">
                    Не зарегистрирован?
                    <router-link to="/signup" tag="a" class="text-info text-gradient font-weight-bold">Создать аккаунт</router-link>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                <div class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" v-bind:style="{ 'background-image': 'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')' }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import router from '@/router'
import api from '../services/api.js'

export default {
  name: 'Login',
  data() {
    return {
      user: this.$store.getters.USER,
      login: '',
      password: '',
      disabled: true,
      error: false,
      message: '',
    }
  },
  components: {
    router
},
  created() {
    
  },
  mounted() {
  },
  watch: {
    login: function (val) {
      if (this.login.length < 4 || this.password.length < 8) {
        this.disabled = true
      }
      else {
        this.disabled = false
      }
    },
    password: function (val) {
      if (this.login.length < 4 || this.password.length < 8) {
        this.disabled = true
      }
      else {
        this.disabled = false
      }
    }
  },
  beforeDestroy() {
  },
  methods: {
    loginUser () {
      this.disabled = true
      api.post('/api/login', {login: this.login, password: this.password}).then((res) => {
        if (res.data.error) {
          this.error = true
          this.message = res.data.message
          setTimeout(() => {
            this.disabled = false
            this.error = false
          }, 1000)
        }
        else {
          this.user.token = res.data.token
          this.user.auth = true
          this.user.id = res.data.id
          this.$store.commit('SET_USER', this.user)
          location.href ='/'
        }
      })
    }
  }
}
</script>

<style scoped>
  </style>
<template>
  <main class="main-content  mt-0">
    <section class="min-vh-100 mb-8">
      <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg" v-bind:style="{ 'background-image': 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')' }">
        <span class="mask bg-gradient-dark opacity-6"></span>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5 text-center mx-auto">
              <h1 class="text-white mb-2 mt-5">Привет!</h1>
              <p class="text-lead text-white">После регистрации дождись активации аккаунта.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row mt-lg-n10 mt-md-n11 mt-n10">
          <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <div class="card z-index-0">
              <div class="card-header text-center pt-4">
                <h5>Создать аккаунт</h5>
                <div v-if="error" class="alert alert-danger text-white" role="alert">
                    <strong>{{ message }}</strong>
                </div>
                <div v-if="success" class="alert alert-success text-white" role="alert">
                    <strong>{{ message }}</strong>
                </div>
              </div>
              <div class="card-body">
                <form role="form text-left">
                  <div class="mb-3">
                    <input v-model="login" placeholder="Логин" class="form-control validate" type='text' name='login' id='login' />
                  </div>
                  <div class="mb-3">
                    <input v-model="telegram" placeholder="@Telegram" class='form-control validate' type='text' name='telegram' id='telegram' />
                  </div>
                  <div class="mb-3">
                    <input v-model="password" placeholder="Пароль" class='form-control validate' type='password' name='password' id='password' />
                  </div>
                  <div class="mb-3">
                    <input v-model="confirmPassword" placeholder="Подтвердить пароль" class='form-control validate' type='password' name='confirmPassword' id='confirmPassword' />
                  </div>
                  <div class="text-center">
                    <button @click="signupUser()" :disabled="disabled" name='btn_login' type="button" class="btn bg-gradient-dark w-100 my-4 mb-2">Зарегистрироваться</button>
                  </div>
                  <p class="text-sm mt-3 mb-0">Есть аккаунт? <router-link tag="a" to="/login" class="text-dark font-weight-bolder">Страница входа</router-link></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import router from '@/router'
import api from '../services/api.js'

export default {
  name: 'RegistrationView',
  data() {
    return {
      login: '',
      telegram: '',
      password: '',
      confirmPassword: '',
      disabled: true,
      error: false,
      success: false,
      message: '',
    }
  },
  components: {
    router
},
  created() {
  },
  mounted() {
  },
  watch: {
    login: function (val) {
      if (this.login.length < 4 || this.telegram.length < 3 || this.password.length < 8 || this.password !== this.confirmPassword) {
        this.disabled = true
      }
      else {
        this.disabled = false
      }
    },
    telegram: function (val) {
      if (this.telegram.substring(0, 1) !== '@') {
        this.telegram = '@' + this.telegram
      }
      if (this.login.length < 4 || this.telegram.length < 3 || this.password.length < 8 || this.password !== this.confirmPassword) {
        this.disabled = true
      }
      else {
        this.disabled = false
      }
    },
    password: function (val) {
      if (this.login.length < 4 || this.telegram.length < 3 || this.password.length < 8 || this.password !== this.confirmPassword) {
        this.disabled = true
      }
      else {
        this.disabled = false
      }
    },
    confirmPassword: function (val) {
      if (this.login.length < 4 || this.telegram.length < 3 || this.password.length < 8 || this.password !== this.confirmPassword) {
        this.disabled = true
      }
      else {
        this.disabled = false
      }
    }
  },
  beforeDestroy() {
  },
  methods: {
    signupUser () {
      this.disabled = true
      api.post('/api/signup', {login: this.login, password: this.password, telegram: this.telegram}).then((res) => {
        if (res.data.error) {
          this.error = true
          this.message = res.data.message
          setTimeout(() => {
            this.disabled = false
            this.error = false
          }, 1000)
        }
        else {
          this.success = true
          this.message = res.data.message
          setTimeout(() => {
            this.$router.push('/')
          }, 2000)
        }
      })
    }
  }
}
</script>

<style scoped>
    body {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
    }

    main {
      flex: 1 0 auto;
    }

    body {
      background: #fff;
    }

    .input-field input[type=date]:focus + label,
    .input-field input[type=text]:focus + label,
    .input-field input[type=login]:focus + label,
    .input-field input[type=password]:focus + label {
      color: #e91e63;
    }

    .input-field input[type=date]:focus,
    .input-field input[type=text]:focus,
    .input-field input[type=login]:focus,
    .input-field input[type=password]:focus {
      border-bottom: 2px solid #e91e63;
      box-shadow: none;
    }
  </style>
<template>
  <Sidebar />
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
  <Header msg="Статистика" />
  <div class="container-fluid py-4">
    <Statistic />
    <Footer />
  </div>
  </main>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Statistic from '@/components/Statistic.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Statistic,
    Sidebar,
    Footer
}
}
</script>

import axios from 'axios'
import store from '../store/index.js'

const api = axios.create({
  baseURL: 'https://buzz-api.site' // 'https://apidom.site' // 'https://b-servers.site' // 'http://localhost:80' // 'https://ar-small.ga' // 'http://188.225.60.113/https://ar-small.ga'
})

api.CancelToken = axios.CancelToken
api.isCancel = axios.isCancel

/*
* The interceptor here ensures that we check for the token in local storage every time an ajax request is made
*/
api.interceptors.request.use(
 (config) => {
   
   const token = store.state.user.token

   if (token) {
     console.log(config.headers)
    // config.headers.Authorization = `Bearer ${token}`
    // config.headers.common.Authorization = `Bearer ${token}`
    config.headers["Authorization"] = token
     // config.headers['x-access-tokens'] = token
   }

   return config
 },

 (error) => {
   return Promise.reject(error)
 }
)

export default api
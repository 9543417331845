<template>
  <!-- Navbar -->
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
      <div class="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Панель</a></li>
            <li class="breadcrumb-item text-sm text-dark active" aria-current="page">{{ msg }}</li>
          </ol>
          <h6 class="font-weight-bolder mb-0">{{ msg }}</h6>
        </nav>
        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <div class="ms-md-auto pe-md-3 d-flex align-items-center">
          </div>
          <ul class="navbar-nav  justify-content-end">
            <!--<li class="nav-item d-flex align-items-center">
              <button @click="videoPlay()" class="btn btn-warning btn-sm mb-0 me-3" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/Jfrjeg26Cwk" data-bs-target="#myModal">Всплакнуть на оплату</button>
            </li>-->
            <li class="nav-item d-flex align-items-center">
              <a href="/payouts" class="btn btn-outline-primary btn-sm mb-0 me-3" >{{ $store.getters.USERDATA.balance }} USD</a>
            </li>
            <li class="nav-item d-flex align-items-center">
              <a href="https://t.me/+7JKA_DC1g_sxMzRi" class="btn bg-gradient-info btn-sm mb-0 me-3" target="_blank" >
                <i class="fa fa-brands fa-telegram me-sm-1"></i>
                <span class="d-sm-inline d-none">Канал. новости</span>
              </a>
            </li>
            <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a href="javascript:;" class="nav-link text-body p-0" id="iconNavbarSidenav">
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </a>
            </li>
            <li class="nav-item d-flex align-items-center ps-2">
              <button @click="exitApp()" class="btn btn-outline-primary btn-sm mb-0 me-3" >
                <i class="fa fa-user me-sm-1"></i>
                <span class="d-sm-inline d-none">Выйти</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
    <!-- Modal -->
    <div class="modal fade" @click="closeVideo()" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="ratio ratio-16x9">
              <iframe ref="video" class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div> 
</template>

<script>
import api from '../services/api.js'

export default {
  name: 'Header',
  data() {
    return {
      videoSrc: 'https://www.youtube.com/watch?v=8iLzkk7l1yU',
    }
  },
  components: {
  },
  props: {
    msg: String
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  beforeDestroy() {
  },
  methods: {
    exitApp () {
      localStorage.clear()
      location.href ='/'
    },
    videoPlay () {
      this.$refs.video.src = this.videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0"
    },
    closeVideo () {
      this.$refs.video.src = ''
    }
  }
}
</script>

<style scoped>
.router-link-exact-active {
  background-color: #0d47a1;
  cursor: pointer;
}
</style>
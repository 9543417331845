<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header pb-0">
          <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
            <button @click="reload()" :disabled="disabled" type="button" class="btn btn-outline-primary btn-sm text-end">Обновить</button>
            <div class="btn-group dropdown" role="group">
              <button id="btnGroupDrop1" type="button" class="btn btn-outline-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                {{ offerId === 0 ? 'Сайт': `ID ${offerId}` }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <li><span @click="sortOffer(0)" class="dropdown-item" style="cursor: ;pointer">Все сайты</span></li>
                <li><span @click="sortOffer(1)" class="dropdown-item" style="cursor: ;pointer">сайт 1</span></li>
                <li><span @click="sortOffer(2)" class="dropdown-item" style="cursor: ;pointer">сайт 2</span></li>
                <li><span @click="sortOffer(3)" class="dropdown-item" style="cursor: ;pointer">сайт 3</span></li>
              </ul>
            </div>
            <div v-if="disabled" class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Дата</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Хит/Хост</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">На оплату</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Заказы</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Оплачено</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">EPC</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Заработано</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(el, key) in statistic" :key="key">
                  <td class="align-middle text-center">
                    <span :class="key === 0 ? 'bold' : 'font-weight-bold'" class="text-secondary text-xs">{{ el.invoiceDate }}</span>
                  </td>
                  <td class="align-middle text-center">
                    <span :class="key === 0 ? 'bold' : 'font-weight-bold'" class="text-secondary text-xs">{{ el.hit }} / {{ el.host }}</span>
                  </td>
                  <td class="align-middle text-center">
                    <span :class="key === 0 ? 'bold' : 'font-weight-bold'" class="text-secondary text-xs">{{ el.payin }}</span>
                  </td>
                  <td class="align-middle text-center">
                    <span :class="key === 0 ? 'bold' : 'font-weight-bold'" class="text-secondary text-xs">{{ el.order }}</span>
                  </td>
                  <td class="align-middle text-center">
                    <span :class="key === 0 ? 'bold' : 'font-weight-bold'" class="text-secondary text-xs">{{ el.successOrder }}</span>
                  </td>
                  <td class="align-middle text-center">
                    <span :class="key === 0 ? 'bold' : 'font-weight-bold'" class="text-secondary text-xs" v-html="(el.userBalance > 0 ? ((el.userBalance / ((el.hit > 0 ? el.hit : 1)))) : 0).toFixed(2) + ' USD'"></span>
                  </td>
                  <td class="align-middle text-center">
                    <span :class="key === 0 ? 'bold' : 'font-weight-bold'" class="text-secondary text-xs">{{ el.userBalance }} USD</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" :class="{'disabled': page < 1}">
                <a class="page-link" @click="prevP()" style="cursor: pointer;" aria-label="Previous">
                  <i class="fa fa-angle-left"></i>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" style="cursor: pointer;">{{ page + 1 }}</a></li>
              <li class="page-item" :class="{'disabled': (page + 1) >= totalPage}">
                <a class="page-link" @click="nextP()" style="cursor: pointer;" aria-label="Next">
                  <i class="fa fa-angle-right"></i>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../services/api.js'

export default {
  name: 'Statistic',
  data() {
    return {
      disabled: false,
      user: this.$store.getters.USER,
      statistic: this.$store.getters.USERSTATISTIC,
      page: 0,
      totalPage: 0,
      size: 7,
      offerId: 0,
      AUDIO: {
        CLICK: new Audio(require('@/assets/media/click.mp3'))
      },
    }
  },
  components: {
},
  created() {
    this.userStatistic()
  },
  mounted() {
  },
  watch: {
  },
  beforeDestroy() {
  },
  methods: {
    sortOffer (id) {
      this.offerId = id
      this.AUDIO.CLICK.play()
      this.userStatistic()
    },
    prevP () {
      this.page--
      this.userStatistic()
    },
    nextP () {
      this.page++
      this.userStatistic()
    },
    reload () {
      this.page = 0
      this.userStatistic()
    },
    userStatistic () {
      this.disabled = true
      api.get(`/api/userstatistic/${this.user.id}/${this.page}/${this.size}/${this.offerId}`).then((res) => {
        if (!res.data.error) {
          this.statistic = res.data.userStatistic
          this.totalPage = res.data.totalPage
          this.$store.commit('SET_USERSTATISTIC', this.statistic)
          setTimeout(() => { this.disabled = false }, 1000)
        }
        if (res.data.error && res.data.message === 'unlogin') {
          this.$store.commit('SET_USER', {auth: false, token: false})
          this.$router.push('/')
        }
      })
    }
  }
}
</script>

<template>
  <router-view/>
</template>

<script>
import api from './services/api.js'

export default {
  data() {
    return {
      user: this.$store.getters.USER,
      userData: this.$store.getters.USERDATA,
      interval: null,
      i: 0,
      AUDIO: {
        BALANCE: new Audio(require('@/assets/media/money.mp3'))
      },
    }
  },
  components: {
    
    },
  beforeDestroy() {
    
  },
  created() {
    if (localStorage.getItem('user')) {
      this.$store.commit('SET_USER', JSON.parse(localStorage.getItem('user')))
      this.user = this.$store.getters.USER
      for (let a = 0; a < 10; a++) {
      try{
        clearTimeout(a)
      }
      catch (e) {
        //lo
      }
    }
    this.userInfo()
    }
  },
  mounted() {
  },
  methods: {
    exitApp () {
      localStorage.clear()
      location.href ='/'
    },
    userInfo () {
      api.post('/api/userinfo', {id: this.user.id}).then((res) => {
        if (!res.data.error) {
          if (this.i !== 0 && this.userData.balance < res.data.userData.balance) {
            this.AUDIO.BALANCE.play()
          }
          this.userData = res.data.userData
          this.$store.commit('SET_USERDATA', this.userData)
        }
        if (res.data.error && res.data.message === 'unlogin') {
          this.$store.commit('SET_USER', {auth: false, token: false})
          this.$store.commit('SET_USERDATA', {})
          localStorage.clear()
          location.reload()
        }
        setTimeout(() => {
          this.i = 1
          this.userInfo()
        }, 10000)
      })
    }
  }
}
</script>

<style>
.bold {
  font-weight: 900 !important;
}
</style>

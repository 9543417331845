import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegistrationView from '../views/RegistrationView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
    // component: () => import('../views/LoginView.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: RegistrationView
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/OrdersView.vue')
  },
  {
    path: '/offers',
    name: 'offers',
    component: () => import('../views/OfferView.vue')
  },
  {
    path: '/offers-private',
    name: 'offersP',
    component: () => import('../views/OfferPrivateView.vue')
  },
  {
    path: '/payouts',
    name: 'payouts',
    component: () => import('../views/PayOutView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/AdminView.vue')
  },
  {
    path: '/admin/orders',
    name: 'adminorders',
    component: () => import('../views/AdminOrdersView.vue')
  },
  {
    path: '/admin/offers',
    name: 'adminoffers',
    component: () => import('../views/OfferAdminView.vue')
  },
  {
    path: '/admin/engine',
    name: 'engine',
    component: () => import('../views/EngineView.vue')
  },
  {
    path: '/admin/users',
    name: 'users',
    component: () => import('../views/AdminUsersList.vue')
  },
  {
    path: '/admin/payouts',
    name: 'adminpayouts',
    component: () => import('../views/AdminPayoutsView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/signup']
  const authRequired = !publicPages.includes(to.path)
  const authenticated = store.getters.USER.auth

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !authenticated) {
    next('/login')
  } else {
    next()
  }
})

export default router

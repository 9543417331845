import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      auth: false,
      token: false
    },
    userData: {},
    userOrders: {},
  },
  getters: {
    USER: state => {
      return state.user
    },
    USERDATA: state => {
      return state.userData
    },
    USERORDERS: state => {
      return state.userOrders
    },
  },
  mutations: {
    SET_USER: (state, payload) => {
      localStorage.setItem('user', JSON.stringify(payload))
      state.user = payload
    },
    SET_USERDATA: (state, payload) => {
      state.userData = payload
    },
    SET_USERORDERS: (state, payload) => {
      state.userOrders = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
